<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card style="height: 300px">
          <template #header>
            <span style="color: #5a5e66">{{$t('msg.route.quote')}}</span>
          </template>
          <table>
            <caption style="font-size: small;float: left;margin-bottom: 15px">Create New Quote:</caption>
            <tr>
              <td>
                <router-link to="/rateInquiry/ofQuery" class="myLinkInTD">1.Ocean Freight</router-link>
              </td>
              <td>
                <router-link to="/rateInquiry/chbQuote" class="myLinkInTD">2.Import Customs</router-link>
              </td>
              <td>
                <router-link to="/rateInquiry/comboQuote" class="myLinkInTD">3.Combo: Ocean Freight + Dray </router-link>
              </td>
            </tr>
          </table>
          <table>
            <tr><router-link to="/rateInquiry/drayBatchQuote" class="myLink">4.Dray LSP/Spot Rate Model (Support Multiple Containers/Ports/DestType Model)</router-link></tr>
            <tr><router-link to="/rateInquiry/drayQuote" class="myLink">5.Dray Quote And Order In CSOP/Brokerage Model</router-link></tr>
          </table>
          <el-divider></el-divider>
          <table>
            <caption style="font-size: small;float: left;margin-bottom: 15px">Review Quotes:</caption>
            <tr><router-link to="/record/quotes" class="myLink">All Issued Quotes </router-link></tr>
          </table>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card style="height: 300px">
          <template #header>
            <span style="color: #5a5e66">{{$t('msg.route.order')}}</span>
          </template>
          <table>
            <caption style="font-size: small;float: left;margin-bottom: 15px">Create Dray Order:</caption>
            <tr>
              <router-link to="/dray/manage" class="myLink">1.From Tracking Systems</router-link>
            </tr>
            <tr>
              <router-link to="/record/quotes" class="myLink">2.From Issued Quotes (Support Multiple Containers Model)</router-link>
            </tr>
            <tr>
              <router-link to="/rateInquiry/drayQuote" class="myLink">3.Create Quote Then Create Order (CSOP/Brokerage Model)</router-link>
            </tr>
          </table>
          <el-divider></el-divider>
          <table>
            <caption style="font-size: small;float: left;margin-bottom: 15px">Review Orders:</caption>
            <tr><router-link :to="{ path: '/record/orders'}" class="myLink">All Orders </router-link></tr>
            <tr><router-link to="/dray/portInfoEdit/" class="myLink">Dray Orders Information Update </router-link></tr>
          </table>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card style="height: 300px">
          <template #header>
            <span style="color: #5a5e66">{{$t('msg.route.track')}}</span>
          </template>
          <table>
            <caption style="font-size: small;float: left;margin-bottom: 15px">Tracking Systems:</caption>
            <tr>
              <td><router-link to="/record/myChart" class="myLinkInTD">1.{{$t('msg.route.myChart')}}</router-link></td>
              <td><router-link to="/track/terminal49" class="myLinkInTD">2.{{$t('msg.route.term49')}}</router-link></td>
              <td><router-link to="/e2eTrack" class="myLinkInTD">3.{{$t('msg.route.smsTrack')}}</router-link></td>
            </tr>
          </table>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card style="height: 300px">
          <template #header>
            <span style="color: #5a5e66">{{$t('msg.route.accounting')}}</span>
          </template>
          <table>
            <caption style="font-size: small;float: left;margin-bottom: 15px">Accounting Information:</caption>
            <tr>
              <td><router-link to="/Billing/billStatus" class="myLinkInTD">1.{{$t('msg.route.billStatus')}}</router-link></td>
              <td><router-link to="/Billing/interimAP" class="myLinkInTD">2.{{$t('msg.route.interimAP')}}</router-link></td>
              <td><router-link to="/Billing/interimAR" class="myLinkInTD">3.{{$t('msg.route.interimAR')}}</router-link></td>
              <td><router-link to="/Billing/myInvoice" class="myLinkInTD">4.{{$t('msg.route.invoice')}} (Edit)</router-link></td>
            </tr>
          </table>
          <el-card v-if="curUser==='me'">
           <table>
            <caption style="font-size: small;float: left;margin-bottom: 15px">QuickBooks:</caption>
            <tr><router-link to="/qboConnected" class="myLink">QuickBooks Connection</router-link></tr>
           </table>
          </el-card>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card style="height: 300px">
          <template #header>
            <span style="color: #5a5e66">{{$t('msg.route.drayRC')}}</span>
          </template>
          <table>
            <caption style="font-size: small;float: left;margin-bottom: 15px">Dray Rate Cards Maintenance:</caption>
            <tr><router-link to="/drayRC/rcList" class="myLink">{{$t('msg.route.drayRCList')}}</router-link></tr>
          </table>
          <el-card v-show="curUser==='me'||curUser==='qing.wang'||curUser==='ray.fu'">
           <table style="margin-top: 10px">
            <caption style="font-size: small;float: left;margin-bottom: 15px">Charge Code Maintenance:</caption>
            <tr><router-link to="/chargeCode/legCodeManage" class="myLink">{{$t('msg.route.legCodeManage')}}</router-link></tr>
            <tr><router-link :to="{ name: 'paramEdit', params: {dataSourceTag: 'uom'}}" class="myLink">{{$t('msg.chargeCode.chargeUOM')}}</router-link></tr>
            <tr><router-link :to="{ name: 'paramEdit', params: {dataSourceTag: 'type'}}" class="myLink">{{$t('msg.chargeCode.chargeType')}}</router-link></tr>
            <tr><router-link to="/chargeCode/chargeCodeEdit" class="myLink">{{$t('msg.route.chargeCodeEdit')}}</router-link></tr>
           </table>
          </el-card>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card style="height: 300px">
          <template #header>
            <span style="color: #5a5e66">{{$t('msg.track.basicInfo')}}</span>
          </template>
          <table>
            <caption style="font-size: small;float: left;margin-bottom: 15px">My Records:</caption>
            <tr><router-link to="/record/address" class="myLink">Address</router-link></tr>
            <tr><router-link to="/dray/driverRecord" class="myLink">Driver Record</router-link></tr>
            <tr><router-link to="/record/chassisRent" class="myLink">Chassis Rental</router-link></tr>
          </table>
        </el-card>
      </el-col>
    </el-row>
 </div>

</template>

<script setup>
const curUser = localStorage.getItem('curUser')

</script>

<style lang="scss" scoped>
.myLink {
  font-size: small;
  float: left;
  color: #009dff;
  text-decoration-line: underline;
}
.myLinkInTD {
  margin-right: 30px;
  font-size: small;
  color: #009dff;
  text-decoration-line: underline;
}
</style>
